import React from 'react'

const Footer = () => {
  return (
    <div>
      <footer>
          © {new Date().getFullYear()}, Spectrum Management Consulting Inc.
      </footer>
    </div>
  )
}

export default Footer
