import { Link, graphql, StaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"

import Img from "gatsby-image"
import './navbar.scss'

import { Fade as Hamburger } from 'hamburger-react'

const activeStyles = {
  backgroundColor: "rgb(55, 53, 47, 0.08)"
}

const Button = ({ children, to, mobile }) => {
  return (
    <Link className={"navbar-button " + (mobile ? "mobile-button" : "")} to={to} activeStyle={activeStyles}>
      {children}
    </Link>
  )
}

const Header = ({ siteTitle, data }) => {
  const [burgerOpen, setBurgerOpen] = useState(false)

  const buttons = [
    { name: "About", to: '/'},
    { name: "Contact", to: '/contact'}
  ]

  const Logo = () => (
    <StaticQuery
            query={graphql`
              query {
                placeholderImage: file(relativePath: { eq: "smc.jpg" }) {
                  childImageSharp {
                    fixed(height: 40) {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            `}
            render={data => (
              <Img className="smc-img" fixed={data.placeholderImage.childImageSharp.fixed} />
            )}
          />
  )

  return (
    <nav className="glob">
      <div className="navbar-content">
        <div className="img">
          <Logo />
          <Link to="/" className="smc">
          <h2 style={{ textDecoration: "none" }} >
            Spectrum Management Consulting
          </h2>
          </Link>
        </div>
        
        <div className="buttons">
          {buttons.map((b, i) => (
            <Button mobile={false} to={b.to} key={i}>{b.name}</Button>
          ))}
          <a className="navbar-get-in-touch"href="mailto: info@spectrumplay.com">
            Get in Touch
          </a>
        </div>

      </div>
      <div className="mobile-nav">
        <div className="img">
        <Logo />
          <div className="smc mobile-smc-title">SMC</div>
        </div>
        <div className="burger-icon">
          <Hamburger toggled={burgerOpen} toggle={setBurgerOpen} />
        </div>
      </div>
      <div className="mobile-dropdown-format">
        {burgerOpen ? (
          <div className="mobile-dropdown-wrapper">
              <div className="mobile-dropdown-content">
                {buttons.map((b, i) => (
                  <Button mobile={true} to={b.to} key={i}>{b.name}</Button>
                ))}
              <a className="navbar-get-in-touch" style={{ padding: "0.7em 2em"}} href="mailto: info@spectrumplay.com">
                Get in Touch
              </a>
            </div>
          </div> 
        ) : null}
      </div>
    </nav>
  )
}

export default Header
