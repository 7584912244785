/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Navbar from "./navbar"
import Footer from './Footer'
import "./layout.css"
import "./App.css"

import styled from 'styled-components';

const Layout = ({ children }) => {
  return (
    <div className="app">
      <Navbar />
      <div
        style={{
          margin: `0 auto`,
          maxWidth: 1000,
          marginTop: "8em",
          padding: `0 1.0875rem 1.45rem`,
        }}
      >
        <main>{children}</main>
        <Footer></Footer>
      </div>
    </div>
  )
}


export default Layout;
